.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'quicksandlight';
  src: url('./assets/fonts/quicksand-variablefont_wght-webfont.woff2'),
    url('./assets/fonts/quicksand-variablefont_wght-webfont.woff');
}

@font-face {
  font-family: 'kaushan_scriptregular';
  src: url('./assets/fonts/kaushanscript-regular-webfont.woff2'),
    url('./assets/fonts/kaushanscript-regular-webfont.woff');
}

.Main-header {
  background-color: #212B3A;
  background-size: cover;
}

.Main-header-second {
  background-image: url("./graphics/1.jpg");

}

.Secondmain-header {
  background-color: #212B3A;
}

.About-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height:100vh;
}

.Contact-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height:100vh;
}

.Lists-header {
  background-color: #212B3A;
}

.Submit-main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height:100vh;
}

.Lists-main-header {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: -50px;
}

.Submissions-main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height:100vh;
}

.Submit-header {
  background-color: #212B3A;
}

.carousel-header {
  width: 75%;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}

.carousel-arrow {
  align-self: center;
  margin-top: -35px;
  opacity: 1;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.carousel-arrow:hover {
  opacity: .6;
}

.home-spacer {
  background-color: #212B3A;
  height: 30px;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.navbar {
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-between;
}

.Top-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.P-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.pagesplit {
  width: 100%;
  height: 60px;
  background-color: rgb(43, 42, 42);
}

.navbar-links {
  margin-right: -115px;
  margin-left: 200px;
  display: flex;
  flex-direction: row;
}

.stats-collab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-bottom: 100px;
}

.Collab-header img {
  max-width: 400px;
  margin-right: 10px;
  background-color: transparent;
  border: transparent;
}

.Collab-w-text-header {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  margin-left: -50px;
}

.Collab-w-text-header h3 {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: calc(17px + 0.2vw);
  color: white;
}

.stats-header {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 25px;
  margin-left: 20px;
}

.stats-header h3 {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: calc(17px + 0.2vw);
  color: white;
}

.stats-header h1 {
  margin-top: 10px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 200;
  font-size: calc(10px + 0.4vw);
  color: white;
}

.stats {
  margin-top: 10px;
}

.stat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 320px;
  margin-top: 10px;
}

.stat-name {
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  font-size: calc(17px + 0.2vw);
  font-weight: 500;
  text-align: center;
  color: white;
}

.stat-number {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: calc(17px + 0.2vw);

  text-align: center;
  color: white;
}

.Middle-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Home-header {
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #212B3A;
  margin-bottom: 40px;
  margin-top: -50px;
}

.Submit-wrapper {
  margin-top: 130px;
}

.bottom-logo {
  width: 150px;
  margin-top: -10px;
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.bottom-logo:hover {
  opacity: .7;
}

:root {
  font-size: 16px;
}

.subheader .subp {
  margin-top: 15px;
  font-size: 20px;
  margin-bottom: -15px;
}

.Secondapp-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Labels-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Bottom-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.932);
  color: black;
}

.mainlogo {
  font-family: 'Kaushan Script', cursive;
  font-weight: bold;
  font-size: calc(110px + 5vw);
  margin-bottom: 70px;
  background-color: transparent;
  color: rgb(245, 112, 51);
}

.summaryh2 {
  margin-top: -150px;
  margin-bottom: 30px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: calc(18px + 0.7vw);
}

.summaryh2-second {
  margin-top: 30px;
  margin-bottom: 50px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: calc(18px + 0.7vw);
}

.summaryh2error {
  margin-top: -50px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: red;
}

.secondsummaryh2 {
  margin-top: 70px;
  margin-bottom: 50px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: calc(15px + 1.7vw);
}

.secondsummaryh2 h1 {
  margin-top: 10px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 200;
  font-size: calc(10px + 0.4vw);
}


.statsmain {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.summaryp {
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  font-size: calc(15px + 0.2vw);
  font-weight: 400;
  max-width: 650px;
  text-align: center;
  color: white;
}

.summaryplink {
  font-weight: 700;
  color: #F4B95C;
}

.toplogo a {
  font-family: 'Kaushan Script', cursive;
  font-weight: bold;
  text-decoration: none;
  font-size: 22px;
  display: flex;
  margin-left: -100px;

  color: rgb(245, 112, 51);
}

.toplogo {
  width: auto;
}

.Toph3-header {
  display: none;
  background-color: #005999;
}

.navbar-logo {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mobile-logo {
  display: none;
}

.logo {
  padding-top: 2rem;
  margin-left: 100px;
  max-width: 250px;
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.logo:hover {
  opacity: .7;
}

.mamba {
  padding-top: 1.5rem;
  margin-left: 50px;
  max-width: 30px;
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.mamba:hover {
  opacity: .7;
}

.navbar-options {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.toph3 {
  text-decoration: none;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  display: block;
  color: white;
  padding-top: 2.5rem;
  padding-left: 2.8rem;
  
}

.toph3:hover,
.toph3 a:hover {
  text-decoration: none;
  color: #2278b4;
}

.mambah3 {
  text-decoration: none;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: block;
  color: #009632;
  padding-top: 2rem;
  padding-left: 3.2rem;
}

.mambah3:hover,
.mambah3 a:hover {
  text-decoration: none;
  color: #003097;
}

.droph3 {
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  color: white;
  padding: 1.2rem;
  margin-bottom: 0;
  margin-top: 0;
}

.droph3:hover,
.droph3 a:hover {
  text-decoration: none;
  background-color: #555;
}

a:hover {
  text-decoration: none;
}

.bottom {
  width: 500px;
  margin-left: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bottomh3 {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  color: black;
}

.bottomlogo {
  font-family: 'Kaushan Script', cursive;
  font-weight: bold;
  font-size: 22px;
  color: rgb(245, 112, 51);
}

.contact {
  border-style: solid;
  border-color: #555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 400px;
  margin-bottom: 15px;
}

.contact img {
  margin-right: 5px;
  width: 40px;
  margin-bottom: 7px;
}

.contact .mamba {
  margin-right: 15px;
  width: 20px;
  margin-bottom: 20px;
}

.contact h2 {
  margin-left: 10px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 22px;
}

.App-link {
  color: #61dafb;
}

.socials {
  margin: 11px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: transparent;
}

.chart {
  width: 1000px;
  padding: 2rem;
}

.summaryb {
  display: flex;
  justify-content: center;
  padding: 0.3rem;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
  font-family: 'Quicksand', sans-serif;
  font-size: calc(15px + 0.2vw);
  font-weight: 600;
  background-color: #F57E6B;
  min-width: 170px;
}

.mambab {
  display: flex;
  justify-content: center;
  padding: 0.3rem;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
  font-family: 'Quicksand', sans-serif;
  font-size: calc(15px + 0.2vw);
  font-weight: 600;
  background-color: #009632;
  min-width: 170px;
}

.submittedb {
  text-decoration: none;
  width: 100%;
  border-color: white;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 80%;
  color: wheat;
  font-family: 'Quicksand', sans-serif;
  font-size: 30px;
  font-weight: 600;
  background-color: rgb(31, 31, 31);
}

.summaryb a {
  color: inherit;
}

.mambab a {
  color: inherit;
}

.submittedb a {
  color: inherit;
}

.summaryb:hover {
  background-color: #2E91D9;
  color: white;
}

.mambab:hover {
  background-color: #000000;
  color: white;
}

.submittedb:hover {
  background-color: rgb(165, 170, 187);
  color: rgb(0, 0, 0);
}

.toggle-hamburger {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-hamburger .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.subheader {
  margin-bottom: 50px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: calc(40px + 0.4vw);
}

.subp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subp p {
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
  color: white;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 60px;
}

.subp .submitp {
  text-align: center;
}

.subp .holidayp {
  text-align: center;
  color: red;
}

.submitp {
  width: 860px;
}

.holidayp {
  width: 860px;
}

.submitheader {
  margin-top: 50px;
  font-family: 'Quicksand', sans-serif;
  font-size: 40px;
  font-weight: 400;
}

.submitheader h3 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 200;
}

.subdate {
  font-weight: 200;
  margin-top: 10px;
  font-size: calc(15px + 0.2vw);
}

.substatus {
  color: white;
  font-size: calc(17px + 0.2vw);
}

.subaccepted {
  margin-top: 20px;
  color: green;
  font-size: calc(17px + 0.2vw);
}

.subdeclined {
  margin-top: 20px;
  color: red;
  font-size: calc(17px + 0.2vw);
}

.subpending {
  margin-top: 20px;
  color: #2E91D9;
  font-size: calc(17px + 0.2vw);
}

.submitplayer {
  height: 80px;
  width: 400px;
}

.submitlists {
  padding: 2.5rem;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #1A232F;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  color: white;
  font-weight: 600;
}

.aboutp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutp p {
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  font-size: 18px;
  width: 570px;
  font-weight: 400;
  text-align: justify;
  color: white;
  display: block;
  margin-block-end: 2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.aboutp h2 {
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  font-size: 20px;
  font-weight: 700;
}

.submittedp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submittedp p {
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  font-size: 20px;
  text-align: center;
  color: white;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 60px;
  width: 600px;
}

.viewlistsb {
  display: flex;
  flex-direction: row;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: calc(400px + 0.4vw);
  border-color: transparent;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-size: calc(16.5px + 0.4vw);
  font-weight: 700;
  background-color: #1A232F;
}

.viewlistsb h2 {
  font-family: 'Quicksand', sans-serif;
  font-size: calc(15px + 0.4vw);
}

.viewlistsb h3 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: calc(15px + 0.4vw);
}

.viewlistsb:hover {
  background-color: rgb(165, 170, 187);
  color: rgb(0, 0, 0);
}

.lists-wrapper {
  display: flex;
  flex-direction: row;
}

.lists {
  border-style: groove;
  border-radius: 10px;
  border-color: #1A232F;
  display: flex;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  font-size: calc(13px + 0.2vw);
  text-align: left;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 0.5rem;
  width: calc(400px + 0.4vw);
  color: white;
  font-weight: 600;
}

.lists button {
  border-color: transparent;
  border-radius: 5px;
  font-weight: 700;
  margin-right: 5px;
  background-color: white;
}

.player {
  width: 80px;
  margin-right: 10px;
  height: 80px;
}

.lists img {
  margin-right: 10px;
  width: 70px;
}

.playbtn {
  color: transparent;
  background-color: transparent;
}

.playbtn img {
  width: 50px;

  background-color: transparent;
}

.mainlists {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.lists a {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: inherit;
}

.lists a:hover {
  color: red;
}

.featuringtext {
  color: rgb(126, 126, 126);
  font-weight: 700;
}

.sform {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  font-size: 30px;
  font-weight: 500;
}

.sform .link {
  background-color: transparent;
  border-radius: 10px;
  color: white;
  font-size: 25px;
  height: 60px;
  width: 800px;
  margin-bottom: 20px;
  border-color: white;
  font-weight: 600;
  overflow-wrap: break-word;
}

.sform .desc {
  background-color: transparent;
  border-radius: 10px;
  color: white;
  font-size: 25px;
  height: auto;
  width: 800px;
  margin-bottom: 20px;
  border-color: white;
  font-weight: 600;
  overflow-wrap: break-word;
}

.sform .submit {
  background-color: #F57E6B;
  color: rgb(255, 255, 255);
  font-size: 25px;
  overflow-wrap: break-word;
  font-weight: 700;
  font-size: 25px;
  border-color: transparent;
  border-radius: 10px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sform .submit:hover {
  background-color: rgb(245, 112, 51);
  color: rgb(255, 255, 255);
}

.sform p {
  margin-top: -15px;
  color: red;
  font-size: 20px;
}

.sform .link::placeholder {
  color: rgb(226, 226, 226);
  font-weight: 300;
}

.sform .desc::placeholder {
  color: rgb(226, 226, 226);
  font-weight: 300;
}

.sform .reportdesc::placeholder {
  color: rgb(228, 25, 25);
}

.filter {
  font-family: 'Quicksand', sans-serif;
  font-size: 22px;
}

.filter-options {
  font-family: 'Quicksand', sans-serif;
  font-size: 22px;
}

.filter .filter-select {
  background-color: rgba(94, 89, 89, 0.589);
  color: white;
  border-color: white;
  overflow-wrap: break-word;
}

.sform .report {
  background-color: rgba(94, 89, 89, 0.589);
  color: rgb(228, 25, 25);
  font-size: 25px;
  height: 60px;
  width: 800px;
  margin-bottom: 20px;
  border-color: white;
  overflow-wrap: break-word;
}

.sform .reportdesc {
  background-color: transparent;
  color: white;
  font-size: 25px;
  height: auto;
  width: 800px;
  margin-bottom: 20px;
  border-color: white;
  overflow-wrap: break-word;
}

.contactp {
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  font-size: 19px;
  width: 570px;
  font-weight: 400;
  text-align: center;
  color: white;
  display: block;
  margin-block-end: 2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.adminplayer {
  width: 299px;
  height: 80px;
  margin-bottom: 20px;
}

.admindesc {
  font-size: 20px;
}

.adminlists {
  border-style: ridge;
  border-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 900px;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  padding: 1rem;
  text-align: left;
  margin-top: 20px;
  color: white;
  font-weight: 600;
}

.adminsplit {
  display: flex;
  flex-direction: row;
}

.adminsubs {
  display: flex;
  flex-direction: row;
}

.filterheader {
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  display: flex;
  width: 100px;
  justify-content: center;
}

.filterheader button {
  background-color: rgb(86, 99, 136);
  color: white;
  border-style: none;
  text-decoration: none;
  border-color: white;
  border-radius: 10px;
  margin: 15px;
}

.filterheader button:hover {
  background-color: white;
  color: grey;
}

.dropdown-menu {
  margin-top: -2px;
  background-color: grey;
}

@media only screen and (min-width: 1200px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}

.spotify-player {
  width: 600px;
  height: 355px;
  max-width: 80%;
}

.recenth3 {
  display: none;
}

.loading {
  width: 40px;
  height: 40px;
}

@media (max-width: 300px) {
  .stat {
    width: 250px;
  }
}

@media (max-width: 1200px) {

  .carousel-header {
    width: 96%;
    margin-bottom: 40px;
  }

  .Home-header {
    margin-top: 0;
  }


  .About-header {
    margin-top: 0;

  }

  .Contact-header {
    margin-top: 0;

  }

  .bottom-logo {
    margin-top: 0;
    margin-bottom: 8px;
  }

  .logo {
    padding-top: 1.8rem;
    width: 100px;
    margin-left: 20px;

  }

  .mobile-logo {
    display: flex;
  }

  .navbar {
    display: none;
  }

  .Submit-wrapper {
    margin-top: 0;
  }

  .filter {
    font-size: 18px;
  }

  .filter-options {
    font-size: 18px;
  }

  .spacer {
    min-height: 0;
  }

  .home-spacer {
    min-height: 0;
  }

  .afrobeats-text {
    width: 200px;
  }

  .dropdown {
    display: none;
  }

  .toggle-hamburger {
    display: flex;
    align-items: center;
  }

  .Top-header {
    position: sticky;
    background-color: #F57E6B;
    top: 0;
    width: 100%;
    height: 72px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Toph3-header {
    display: flex;
    margin-top: 27.5px;
    width: 100%;
    flex-direction: column;

    margin-left: 0;
  }

  .Submit-main-header {
    margin-top: 0px;
  }

  .Lists-main-header {
    margin-top: 0px;
    min-height:80vh;
  }

  .Submissions-main-header {
    margin-top: 0px;
  }

  .navbar-links {
    display: none;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    flex-direction: column;
  }

  .toph3 {
    width: 100%;
    padding: 1.2rem 1rem;
    position: relative;
  }

  .mambah3 {
    width: 100%;
    padding: 0.8rem 1rem;
    padding-top: 1.7rem;
    position: relative;
  }

  .stats-header {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    margin-left: 0;
  }

  .stat-name {
    font-size: 17px;
  }

  .stat-number {
    font-size: 17px;
  }

  .recenth3 {
    display: block;
  }

  .navbar-links.active {
    display: flex;
  }

  .toplogo a {
    padding: 1.2rem;
    margin-left: 0;
  }

  .mainlogo {
    font-size: calc(50px + 2vmin);
    margin-bottom: 30px;
    margin-top: 85px;
  }

  .stats-collab-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-bottom: 100px;
  }

  .spotify-player {
    width: 300px;
    height: 300px;
  }

  .Middle-header {
    flex-direction: column;
  }

  .summaryh2 {
    font-size: 23px;
    font-weight: 1000;
    margin-top: 30px;
    padding: 1rem;
    margin-bottom: 10px;
  }

  .summaryh2-second {
    font-size: 23px;
    font-weight: 1000;
    margin-top: 10px;
    padding: 1rem;
    margin-bottom: 20px;
  }

  .summaryh2error {
    font-size: 15px;
    margin-top: -10px;
    padding: 1rem;
    font-weight: 600;
  }

  .P-header {
    margin-right: 3%;
    margin-left: 3%;
  }

  .summaryp {
    text-align: center;
    margin-right: 0;
    width: 100%;
    font-size: 17px;
    max-width: 550px;
    font-weight: 500;
  }

  .stats h3 {
    font-size: 25px;
  }

  .secondsummaryh2 {
    font-size: 38px;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .secondsummaryh2 h1 {
    font-size: 15px;

  }

  .statsmain {
    flex-direction: column;
  }

  .chart {
    height: 100%;
    width: 100%;
    padding: 0.5rem;
  }

  .submitb {
    margin-right: 0;
    font-size: 20px;
    width: 160px;
  }

  .listsb {
    margin: 0;
    font-size: 20px;
    width: 160px;
  }

  .Collab-w-text-header {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }


  .Collab-header img {
    width: 75%;
    max-width: 550px;
    background-color: transparent;
    border: transparent;
    margin-right: 0;
  }

  .Bottom-header {
    flex-direction: column;
    justify-content: center;
    height: 140px;
  }

  .bottomlogo {
    margin-right: 0;
    margin-left: 0;
  }

  .bottomh3 {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .submittedp p {
    margin-top: 0;
    font-size: 18px;
    width: 100%;
    padding: 1rem;
    max-width: 600px;
  }

  .lists-wrapper {
    display: flex;
    margin-bottom:50px;
    flex-direction: column;

  }

  .single-list-wrapper{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .viewlistsb {
    border-color: transparent;
    border-radius: 10px;
    color: white;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    font-size: calc(16.5px + 0.4vw);
    font-weight: 700;
    background-color: #1A232F;
  }

  .mainlists {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    
  }

  .lists {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    width: 100%;
  }

  .lists a {
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    width: 100%;
    margin-right: 5px;
  }

  .lists .player {
    background-image: url("./graphics/background1.jpg");
    width: 80px;
    height: 80px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .submitlists {
    width: 100%;
    flex-direction: column;
    padding: inherit;
  }

  .subaccepted {
    padding: 1rem;
    font-size: 18px;
  }

  .subdeclined {
    padding: 1rem;
    font-size: 18px;
  }

  .subpending {
    padding: 1rem;
    font-size: 18px;
  }

  .subdate {
    margin-top: 15px;
    font-size: 18px;
  }

  .substatus {
    font-size: 18px;
  }

  .submitplayer {
    height: 80px;
    width: fit-content;
    max-width: 250px;
  }

  .subheader {
    margin-top: 70px;
    padding: 1rem;
    font-size: 35px;
    margin-bottom: 30px;
  }

  .submitheader {
    font-size: 30px;
  }

  .submitheader h3 {
    font-size: 16px;
  }

  .subp p {
    text-align: center;
    font-size: 18px;
    margin-top: 0;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
  }

  .submitp {
    width: 100%;
    max-width: 600px;
    padding: 0.5rem;
    font-size: 15px;
  }

  .holidayp {
    width: 100%;
    max-width: 600px;
    padding: 0.5rem;
    font-size: 15px;
  }

  .aboutp p {
    text-align: center;
    margin-top: -10px;
    padding: 1rem;
    width: 100%;
    font-size: 16px;
    margin-right: 15px;
    margin-left: 15px;
    max-width: 600px;
  }

  .aboutp h2 {
    width: 100%;
    font-size: 18px;
  }

  .sform {
    max-width: 700px;
    padding: 1rem;
    width: 100%;
  }

  .sform .link {
    font-size: 20px;
    width: 100%;
  }

  .sform .desc {
    font-size: 20px;
    height: 200px;
    width: 100%;
  }

  .sform .report {
    font-size: 20px;
    width: 100%;
  }

  .sform .reportdesc {
    font-size: 20px;
    height: 200px;
    width: 100%;
  }

  .contact {
    width: 90%;
    max-width: 400px;
  }

  .contact h2 {
    font-size: 18px;
  }

  .contactp {
    margin-top: -10px;
    padding: 1rem;
    width: 100%;
    font-size: 17px;
    margin-right: 15px;
    margin-left: 15px;
    max-width: 600px;
  }

  .bottom {
    flex-direction: column;
    margin-right: 0;
    margin-left: 0;
    width: 100px;
    max-width: 120px;
  }

  .adminsplit {
    flex-direction: column;
  }
}